.h1login {
    position: absolute;
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.formlogin {
    border-radius: 10px;
    max-width: 300px;
    margin: 30px auto;
    text-align: left;
    text-align: center;
}
.formlogin .form_group {
    margin-bottom: 15px;
    position: relative;
}
.formlogin .form_group span {
    font-size: 18px;
    font-weight: 700;
    display: block;
    margin-bottom: 15px;
    color: #000;
}
.formlogin .form_group input {
    font-size: 16px;
    color: #282828;
    border: 1px solid #2199cb;
    background: #fff;
    width: 100%;
    padding: 0;
    line-height: 2;
    outline: none;
    padding: 5px 15px;
    border-radius: 5px;
}
.formlogin .form_group svg {
    position: absolute;
    left: 275px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: #333;
}
.formlogin p {
    color: rgb(228, 18, 18);
    font-size: 13px;
    display: inline-block;
    margin-left: 10px;
}
.formlogin .regisnow {
    font-size: 16px;
    text-align: right;
}
.formlogin .regisnow a {
    color: #f0c882;
}
.formlogin .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}
.formlogin .row div {
    display: flex;
    align-items: center;
    font-size: 14px;
}
.formlogin .row img {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin-right: 10px;
}
.btngold {
    background: #f0c882;
    border: 0;
    color: #fff;
    font-size: 18px;    
    font-weight: 700;
    padding: 15px;
    border: 0;
    border-radius: 10px;
    width: 100%;
    margin: 30px 0;
}
.login_text {
    margin-top: -10px;
    margin-bottom: 30px;
} 