.banner-game {
    width: 100%;
    position: relative;
}
.banner-game img {
    width: 100%;
}
.menu-box-game {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.menu-box-game-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
}
.box-game {
    margin: 15px;
    position: relative;
    overflow: hidden;
}
.box-game:after {
    display: none;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9;
    transition: all 0.5s;
    border-radius: 10px;
}
.box-game:hover:after {
    display: block;
}
.box-game-image img {
    vertical-align: bottom;
    width: 100%;
}
.box-game-play {
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all 0.3s;
    z-index: 10;
}
.box-game-play span {
    width: 100px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border: 0 solid #000;
    border-radius: 5px;
    color: #fff;
    background: #00a6f0;
    cursor: pointer;
}
.box-game:hover .box-game-play {
    opacity: 1;
}
.sub_logo {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 120px;
}
.view-more {
    font-size: 16px;
    color: #ff0000;
    border: 2px solid #ff0000;
    border-radius: 50px;
    padding: 5px 15px;
    width: 200px;
    margin: 0 auto;
    text-transform: uppercase;
    font-weight: bold;
}
.relative-input {
    position: relative;
}
.absolute-input {
    position: absolute;
    top: 0.1rem;
    right: calc(5% + 0.1rem);
    border-radius: 0.26667rem;
    background: #fce406;
    height: 0.97333rem;
    line-height: 0.97333rem;
    padding: 0 0.3rem;
    cursor: pointer;
}
.gameapi-list {
    display: flex;
    flex-flow: row wrap;
}
.gameapi-item {
    width: calc(100% / 3);
    padding: 10px;
    position: relative;
}
.gameapi-item img {
    float: left;
    width: 100%;
    box-shadow: 0 0.02rem 0.06rem rgba(0, 0, 0, 0.06);
    background: #fff;
    border-radius: 10px;
}
.gameapi-name {
    background: linear-gradient(180deg, rgba(232, 35, 35, 0.9), rgba(201, 13, 13, 0.9));
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 10px;
    width: calc(100% - 20px);
    font-size: 13px;
    text-align: center;
    padding: 10px 2px;
    border-radius: 0 0 10px 10px;
}
.bg-games-pro {
    position: relative;
    min-height: 85vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.bg-games-pro.bg-daga {
    background-image: url(../../images/bg_daga0.png.png);
}
.bg-games-pro.bg-esport {
    background-image: url(../../images/bg_esports0.png.png);
}
.bg-games-pro .image-ab {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateX(100%);
    transition: all 0.4s;
}
.bg-games-pro .image-ab.active {
    transform: translateX(0);
    transition: all 0.4s;
}
.bg-games-pro-text {
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    z-index: 2;
}
.bg-games-pro-text h2 {
    font-size: 45px;
    background: -webkit-linear-gradient(#1b74f0, #61c7fd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    margin-bottom: 20px;
    filter: drop-shadow(#bed7ef99 0 3px 6px);
}
.bg-games-pro-text .tab-navigation {
    justify-content: start;
}
.bg-games-pro-text .tab-navigation > li {
    width: 100px;
    height: 100px;
    aspect-ratio: 1;
    line-height: 100px;
    flex: 0 0 100px;
    padding: 0;
    border-radius: 100%;
    background: url(../../images/venue_bj.png);
    background-size: 100% 100%;
    color: #fff;
    filter: drop-shadow(#bed7ef99 0 3px 6px);
}
.bg-games-pro-text .tab-navigation > li.active {
    background-image: url(../../images/venue_active.png);
}
.bg-games-pro-text button {
    background-image: url(../../images/blue_btn.png.png);
    background-size: 100% 100%;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    border: 0;
    padding: 15px 40px;
    margin-top: 20px;
}