html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
    font-size: 0.3rem;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
i {
    font-style: italic;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}
::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
    background-color: #2199cb;
    background-image: -webkit-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
    );
}

* {
    box-sizing: border-box;
}
body {
    background: #f6fbff;
    font-size: 16px;
    line-height: 1.3;
    color: #8491a5;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    width: 100vw;
    overflow-x: hidden;
}
.container {
    width: 100%;
    max-width: 1150px;
    margin: 0 auto;
    padding: 0 15px;
}
a {
    color: #2199cb;
    text-decoration: none;
}
img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}
.d-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
}
input {
    border: 1px solid #00a6f0;
    outline: none;
    font-size: 12px;
    color: #333;
    padding: 5px;
    background: none;
    line-height: 1.8;
}
button {
    background: #00a6f0;
    color: #fff;
    border-radius: 5px;
    border: 0;
    font-size: 16px;
    padding: 6px 20px;
}
.btn {
    background: none;
    color: #00a6f0;
    border-radius: 5px;
    border: 1px solid #00a6f0;
    font-size: 16px;
    padding: 6.3px 12px;
}
.swal-footer {
    text-align: center;
}
.swal-modal:has(.swal-icon--success) .swal-button {
    background: #a5dc86 !important;
}
.swal-modal:has(.swal-icon--error) .swal-button {
    background: #f27474 !important;
}
.swal-modal:has(.swal-icon--warning) .swal-button {
    background: #f8bb86 !important;
}
.swal-modal:has(.swal-icon--info) .swal-button {
    background: #3fc3ee !important;
}
.huongdan {
    background: linear-gradient(90deg, rgba(255, 253, 251, 0.2) 0, #ffe7d6 100%);
    border-radius: 10px;
    border: solid 1px #f9dacb;
    font-size: 13px;
    margin-top: 10px;
    padding: 10px;
    margin: 40px;
}
.huongdan .title {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 15px;
    color: #f55600;
    background: #ffe6d1;
    display: inline-block;
    border-radius: 11px 0 11px 0;
    padding: 3px 8px;
}
.huongdan ul li {
    color: #555;
    font-size: 14px;
    list-style: none;
    margin-bottom: 10px;
    padding-left: 20px;
    margin-left: 20px;
    position: relative;
    text-align: left;
}
.huongdan ul li:before {
    background: #f55600;
    content: "";
    height: 8px;
    left: -2px;
    position: absolute;
    top: 4px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 8px;
}
.huongdan ul li:last-child {
    margin-bottom: 0;
}
button.btn-red,
.btn-red {
    min-width: 141px;
    padding: 0 15px;
    display: inline-block;
    margin: 0 auto;
    height: 34px;
    line-height: 34px;
    background: #ff2f34 !important;
    box-shadow: 0 4px 10px 0 rgba(255, 47, 52, 0.3);
    border-radius: 17px;
    color: #fff;
    cursor: pointer;
}
.text_status {
    border-radius: 5px;
    color: #fff;
    display: inline-block;
    margin: 0 10px;
    padding: 3px 5px;
    line-height: 1;
}
.win,
.success {
    background-color: #6fe26f;
}
.lose,
.fail {
    background-color: #b12424;
}
.pending,
.waiting {
    background-color: #dba700;
}
.cancelled {
    background-color: #61585a;
}
.cancel-bet {
    background: #2492b1;
}
.loading {
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    z-index: 999;
    background: #0000009d;
}
.loader {
    position: relative;
    overflow: hidden;
    display: block;
    height: 50px;
    width: 50px;
    margin: 0;
}
.loader:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 28px;
    height: 28px;
    margin: -16px 0 0 -16px;
    border: 2px solid #2199cb;
    border-top-color: #e1e8ed;
    border-right-color: #e1e8ed;
    border-radius: 100%;
    animation: spinner 1s infinite linear;
    transform-origin: center;
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}
.game-detail {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    padding: 0.32rem;
}
.game-detail > img {
    width: 150px;
    padding-right: 20px;
}
.game-detail > div {
    width: 50%;
}
.game-detail > div:nth-child(2) {
    text-align: left;
    width: calc(50% - 150px);
}
.game-detail-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 2;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#2199cb, #00a6f0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.game-detail-des {
    color: #fff;
}
.game-result {
    text-align: center;
}
.game-result-left {
    display: flex;
    align-items: center;
    justify-content: center;
}
.game-result-left img {
    width: 22px;
    margin-right: 5px;
}
.game-result-left span {
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    display: inline-block;
    color: #555;
}
.game-result-center {
    display: flex;
    justify-content: center;
}
.game-result-center .ball {
    width: 40px;
    margin: 5px;
}
.game-result-center .ball img {
    width: 40px;
    height: 40px;
    filter: hue-rotate(205deg);
}
.game-rate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 0;
}
.game-rate .tab-navigation-game > div {
    display: none;
}
.game-rate .tab-navigation-game > .active {
    display: block;
    background: -webkit-linear-gradient(#2199cb, #00a6f0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 2;
}
.footer-game {
}
.footer-game-top {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 0.3rem;
    color: #fff;
    align-items: center;
}
.footer-game-bottom {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}
.footer-game-bottom button {
    background: #00a6f0;
    box-shadow: 0 0.05333rem 0.10667rem #c5c5da40;
    color: #fff;
    font-size: 15px;
    padding: 7px 15px;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
}
.footer-game-bottom button.btn-reset {
    background: #555;
}
.footer-game-money {
    color: #000;
    font-size: 16px;
    width: 100%;
    text-align: left;
    margin: 15px 0;
}
.footer-game-money span {
    color: #333;
    font-size: 16px;
}
.footer-game-money b {
    color: #00a6f0;
}
.footer-chip {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.footer-chip > div {
    background-size: 100%;
    height: 50px;
    width: 50px;
    margin: 10px 0;
}
.footer-game-top > div:nth-child(2) {
    width: 40%;
}
.footer-game-top > div:nth-child(3) {
    width: 60%;
    text-align: right;
}
.chip-1x {
    background-image: url(../src/images/1x.png);
}
.chip-3x {
    background-image: url(../src/images/3x.png);
}
.chip-5x {
    background-image: url(../src/images/5x.png);
}
.chip-10x {
    background-image: url(../src/images/10x.png);
}
.footer-game-top input {
    border-radius: 5px;
    border: 0;
    font-size: 15px;
    padding: 0 5px;
    width: 100px;
    border: 0.8px solid #c7c7c7;
}
.main-game {
    padding: 0 10px 10px;
}
.popup-change-game {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    background: rgba(27, 1, 6, 0.89);
    padding: 0 0.32rem 0.32rem;
    opacity: 0;
    max-height: 350px;
    overflow: auto;
    transform: translateY(-30%);
    transition: all 0.3s ease;
    z-index: -1;
}
.popup-change-game::-webkit-scrollbar {
    display: none;
}
.popup-change-game.active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease;
    z-index: 1;
}
.tab-navigation-game {
    text-align: left;
}
.tab-navigation-game > div {
    font-size: 13px;
    border: 1px solid #f5c25f;
    border-radius: 5px;
    width: auto;
    display: inline-block;
    margin: 5px;
    color: #333;
    padding: 4px 5px 2px;
    text-align: center;
    cursor: pointer;
}
.tab-navigation-game > .active {
    background: #f5c25f;
    color: #fff;
}
.tab-navigation-game > .tab-game-title {
    background: -webkit-linear-gradient(#2199cb, #00a6f0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    width: 100%;
    border: 0;
    padding: 0;
    margin-top: 4%;
    display: none;
}
.history-sidebar {
    position: relative;
    overflow: hidden;
}
.history-backdrop {
}
.history-sidebar.active .history-backdrop {
    z-index: 9999;
    opacity: 1;
    transition: all 0.4s ease;
}
.history-sidebar .history-main {
}
.history-sidebar.active .history-main {
}
.history-header {
    background: #1b0101;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 0.4rem;
    padding: 0.2rem;
}
.history-close {
    position: absolute;
    top: 0.2rem;
    left: 0.1rem;
    color: #fff;
    font-weight: 700;
}
.time-box {
    background: #2199cb;
    border-radius: 30px;
    color: #fefefd;
    margin-top: 10px;
    display: inline-block;
    padding: 5px 10px 3px;
}
.time-box .number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}
.time-box .number .item {
    padding: 0 4px;
    font-size: 16px;
    font-weight: bold;
}
.text_choose_center {
    background: #f3f5f9;
    border: 1px solid rgba(214, 217, 224, 0.3);
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
}
.text_choose_center2 {
    text-align: center;
    font-size: 13px;
    margin-top: 5px;
}
.text_choose_center3 {
    background: #ffb30099;
    box-shadow: 0 0.05333rem 0.10667rem #c5c5da40;
    border-radius: 0.26667rem;
    padding: 0.3rem 0.3rem 0.5rem;
    text-align: center;
    font-size: 0.34667rem;
    margin-top: 0.33rem;
}
.text_choose_center3.t2a {
    background: #1ac52999;
}
.text_choose_center3.t3a {
    background: #ff6c0099;
}
.text_choose_center3 .state_choose_price > div {
    background: #fff;
    color: #333;
}
.text_choose_center3 .state_choose .chooseItem {
    background: red;
    color: #fff;
}
.text_choose_center .bet_state {
    font-size: 0.3777rem;
    font-weight: bold;
    margin: 0.1rem 0 0.3rem;
    display: inline-block;
    color: #fff;
}
.text_choose_center textarea {
    border: 0;
    outline: none;
    resize: none;
    font-size: 15px;
    width: 100%;
    border-radius: 5px;
    min-height: 270px;
    padding: 15px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05);
}
.text_choose_center .cach-choi {
    color: #333;
    line-height: 1.5;
    text-align: left;
    margin-bottom: 10px;
}
.state_choose {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
}
.state_choose > div {
    flex: 1;
    margin: 5px;
    background: #e5e5e5;
    border: 0;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
}
.state_choose_price > div {
    width: calc(25% - 0.2rem);
    flex: 0 0 calc(25% - 0.2rem);
    padding: 0.28rem 0;
}
.state_choose2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}
.state_choose > div > * {
    display: block;
}
.state_choose2 > div {
    background: #fff;
    padding: 0.6rem 0.28rem;
}
.state_choose > div > i {
    font-size: 0.35rem;
    color: #00b6ff;
    margin-bottom: 0.2rem;
    font-weight: bold;
}
.state_choose .chooseItem {
    background: #f5c25f;
    color: #fff !important;
}
.state_choose .choose_xs {
    width: calc(10% - 5px);
    padding: 5px 0;
    margin: 2.5px;
    flex: unset;
    color: #333;
}
.popup-bet {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    box-shadow: 0 0.05333rem 0.10667rem #c5c5da40;
    width: 100%;
    max-width: 540px;
    z-index: 101;
    padding: 0.3rem;
}
.main:has(.chooseItem) .popup-bet {
    display: block;
}
.main:has(.chooseItem) .footer {
    display: none;
}
.main:has(.tab-game) .footer {
    display: flex !important;
}
.item_choose_footer {
    display: flex;
    justify-content: space-between;
    font-size: 0.35rem;
}
.item_choose_footer .btn-sbmit {
    color: #fff;
    font-size: 0.3rem;
    font-weight: bold;
    text-shadow: 0 0.05333rem 0.02667rem #ff0000;
    border-radius: 1.06667rem;
    border: none;
    background: linear-gradient(180deg, #ee8d8d 0%, #ff0000 100%);
    box-shadow: 0 0.05333rem #e53636;
    padding: 0.1rem 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.item_choose_footer input {
    padding: 0.16rem 0.14667rem;
    margin: 0 0 0 0.2rem;
    font-size: 0.27333rem;
    border: none;
    border-radius: 0.26667rem;
    box-shadow: 0 0.05333rem 0.21333rem #d0d0ed5c;
    border: 1px solid #e5e5e5;
}
.popup-backdrop {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100vh;
    padding: 0.44rem;
    background: #000000c2;
    z-index: 9999;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}
.content-histories {
    position: relative;
    max-height: 550px;
    overflow-y: auto;
    overflow-x: hidden;
}
.content-histories .item_inner {
    padding: 5px 5px 5px 0;
    border-bottom: 1px solid #636363;
    position: relative;
}
.content-histories .item_history {
    text-align: left;
}
.content-histories .id_history_sanh {
    margin: 5px 0 5px;
    font-size: 12px;
    color: #a3a3a3;
}
.content-histories .sanh {
    color: #000;
    font-size: 14px;
    font-weight: bold;
}
.content-histories .time_choose {
    font-size: 12px;
}
.content-histories .money_history {
    text-align: right;
}
.content-histories .money {
    color: #2199cb;
    display: block;
    margin: 0.2rem 0;
    font-size: 14px;
    position: absolute;
    top: 3px;
    right: 5px;
}
.content-histories .type_state {
    margin-left: 3px;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 11px;
    color: #fff;
    display: inline-block;
}
.win {
    background-color: #6fe26f;
}
.lose {
    background-color: #b12424;
}
.pending {
    background-color: #dba700;
}
.cancelled {
    background-color: #61585a;
}
.cancel-bet {
    background: #2492b1;
    cursor: pointer;
    color: #fff;
}
.tab-navigation {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 10px auto 0;
}
.tab-navigation > li {
    background: #c3c3c3;
    border-radius: 5px;
    color: #333;
    cursor: pointer;
    flex: 1 1;
    font-size: 12px;
    font-weight: 600;
    height: 100%;
    margin: 0 10px 10px 0;
    overflow: hidden;
    text-align: center;
}
.tab-navigation > li.active {
    background: #00a6f0;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
    color: #fff;
}
.tab-navigation.tab-bank > li {
    border: 1px solid #ddd;
    background: #fff;
}
.tab-navigation.tab-bank > li.active {
    background: #fff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
    color: #fff;
    border: 1px solid #f00;
}
.tab-game > li {
    font-size: 14px;
    margin: 0 10px 10px 0;
    padding: 10px;
    min-width: 100px;
}
.tab-sidebar > li {
    font-size: 14px;
    margin: 0 10px 10px 0;
    padding: 10px;
    min-width: 100px;
}
.tab-xoso {
    justify-content: center;
}
.tab-xoso > li {
    border: 1px solid #2199cb;
    color: #2199cb;
    max-width: 90px;
    min-width: 90px;
}
.tab-xoso > li.active {
    background: #2199cb;
    color: #fff;
}
.tab-navigation > li:last-child {
    margin-right: 0;
}
.game-box-left {
    float: left;
    width: 16%;
    margin-top: 2%;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;
}
.game-box-right {
    float: right;
    width: 22%;
    margin-top: 2%;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;
}
.game-box-center {
    float: left;
    width: 58%;
    margin: 2%;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 5px;
}
.footer-selected {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
}
.footer-selected > div:first-child {
    width: 75px;
}
.footer-selected > div:last-child {
    color: #ff0000;
    width: calc(100% - 75px);
}
.table-result {
    text-align: center;
    font-family: arial;
    width: 100%;
}
.table-result td {
    text-align: center !important;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
}
.table-result th {
    border-right: 1px solid #ebebeb;
}
.table-result td,
.table-result th {
    vertical-align: middle;
}
.special-prize {
    float: left;
    width: 100%;
    font-size: 28px;
    font-weight: bold;
    color: #ed1c25;
}
.prize1 {
    float: left;
    width: 100%;
    font-weight: bold;
}
.prize2 {
    float: left;
    width: 50%;
    font-weight: bold;
}
.prize3 {
    float: left;
    width: 33.3%;
    font-weight: bold;
}
.prize4 {
    float: left;
    width: 25%;
    font-weight: bold;
}
.prize5 {
    float: left;
    width: 33.3%;
    font-weight: bold;
}
.prize6,
.prize8 {
    float: left;
    width: 33.3%;
    font-weight: bold;
}
.prize7 {
    float: left;
    width: 25%;
    font-weight: bold;
}
[class*="prize"]:empty {
    display: none;
}
.table-result td span {
    height: 45px;
    line-height: 45px;
}
.table-result tr {
    border-bottom: 1px solid #ddd;
}
.popup-close-top {
    position: absolute;
    right: 10px;
    top: 10px;
    font-weight: bold;
    z-index: 1;
    cursor: pointer;
}
.open-game {
    border: 0;
    width: 200px;
    background: #01a69c;
    color: #fff;
    font-size: 20px;
    text-align: center;
    margin: 10px auto 0;
    display: block;
    padding: 10px;
    font-weight: bold;
    border-radius: 15px;
    box-shadow: 0 0.05333rem 0.10667rem #4ddb5f85;
}
.form-sibo {
    display: flex;
    padding: 0.1rem;
}
.form-sibo input {
    border: 1px solid #999;
    border-right: 0;
    color: #333;
    border-radius: 10px 0 0 10px;
    padding: 10px;
    font-size: 16px;
    height: 40px;
    width: 65%;
}
.form-sibo button {
    width: 35%;
    background: linear-gradient(180deg, #fbca8f 0%, #ffb964 100%);
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    line-height: 26px;
    text-align: center;
    border: 1px solid #fbca8f;
    border-radius: 0 10px 10px 0;
}
.open-game {
    border: 0;
    width: 200px;
    background: #2361c9;
    color: #dce30f;
    font-size: 20px;
    text-align: center;
    margin: 10px auto 0;
    display: block;
    padding: 10px;
    font-weight: bold;
    border-radius: 15px;
    box-shadow: 0 0.05333rem 0.10667rem #dce30f85;
}
.popup-close-top {
    position: absolute;
    right: 10px;
    top: 10px;
    font-weight: bold;
}
.view-more {
    font-size: 16px;
    color: #ff0000;
    border: 2px solid #ff0000;
    border-radius: 50px;
    padding: 5px 15px;
    width: 200px;
    margin: 0 auto;
    text-transform: uppercase;
    font-weight: bold;
}
.relative-input {
    position: relative;
}
.absolute-input {
    position: absolute;
    top: 0.1rem;
    right: calc(5% + 0.1rem);
    border-radius: 0.26667rem;
    background: #fce406;
    height: 0.97333rem;
    line-height: 0.97333rem;
    padding: 0 0.3rem;
    cursor: pointer;
}
.gameapi-list {
    display: flex;
    flex-flow: row wrap;
}
.gameapi-item {
    width: calc(100% / 3);
    padding: 10px;
    position: relative;
}
.gameapi-item img {
    float: left;
    width: 100%;
    box-shadow: 0 0.02rem 0.06rem rgba(0, 0, 0, 0.06);
    background: #fff;
    border-radius: 10px;
}
.gameapi-name {
    background: linear-gradient(180deg, rgba(232, 35, 35, 0.9), rgba(201, 13, 13, 0.9));
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 10px;
    width: calc(100% - 20px);
    font-size: 13px;
    text-align: center;
    padding: 10px 2px;
    border-radius: 0 0 10px 10px;
}
.chatcskh {
    position: fixed;
    background: #23243dab;
    display: block;
    height: 45px;
    width: 45px;
    display: flex;
    border-radius: 100%;
    bottom: 150px;
    right: 5px;
    z-index: 9999;
    padding: 5px;
}
.chatcskh img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}
.popup-close-top {
    position: absolute;
    right: 10px;
    top: 10px;
    font-weight: bold;
    z-index: 1;
}
.open-game {
    border: 0;
    width: 200px;
    background: #3bcb4a;
    color: #fff;
    font-size: 20px;
    text-align: center;
    margin: 10px auto 0;
    display: block;
    padding: 10px;
    font-weight: bold;
    border-radius: 15px;
    box-shadow: 0 0.05333rem 0.10667rem #4ddb5f85;
}
.open-yogame {
    cursor: pointer;
    padding: 15px;
}
.bg-egame {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(./image/bg-egame.jpg) no-repeat;
    background-size: cover;
    padding: 20px 0;
    margin-top: -10px;
}
.bg-sicbo {
    background: url(./image/bg.jpg) no-repeat;
    background-size: cover;
    padding: 20px 0;
}
.box-card-game {
    margin: 0;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.25);
    padding: 20px;
}
.card-game {
    position: relative;
    padding: 6px;
    width: 100%;
    height: 260px;
    border-radius: 8px;
    background: url(./image/card_bg.jpg) no-repeat;
    background-size: 100% 100%;
    color: #fff;
    text-align: center;
}
.card-game-image {
    margin: 0 auto;
    width: 153px;
    height: 153px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
.card-game-image img {
    max-height: 120%;
}
.card-game-info {
    padding: 4px 0;
    border-radius: 0 0 5% 5%;
}
.card-game-title {
    margin-top: 10px;
    margin-bottom: 5px;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
}
.card-game-play {
    display: inline-block;
    margin-top: 3px;
    padding: 8px 22px;
    border-radius: 20px;
    color: #4a3124;
    text-align: center;
    font-size: 14px;
    background: #ffc000;
    cursor: pointer;
}
.logo {
    filter: drop-shadow(2px 4px 6px black)
}